@import "../Styles/variable.scss";
@import "../Styles/space.scss";
// Google Font
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&family=Poppins:ital,wght@0,600;0,700;0,800;1,400&display=swap");

/* Bootstrap Icons */

@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-icons/1.4.0/font/bootstrap-icons.min.css");
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Open Sans", sans-serif;
}

body {
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1em;
    line-height: 1.9;
    color: #6f6f6f;
}

body,
button,
input,
textarea {
    font-family: "Open Sans", sans-serif;
    font-size: $normal-font-size;
}

h1,
h2,
h3 {
    font-weight: $font-semi-bold;
}

h1 {
    font-size: $h1-font-size !important;
}

h2 {
    font-size: $h2-font-size !important;
}

h3 {
    font-size: $h3-font-size !important;
}

h4 {
    font-size: $h4-font-size !important;
}

th {
    color: #464545;
    font-weight: 500;
}

.text-gray-600 {
    font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Poppins", sans-serif;
    color: #142c4b;
    margin: 0;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    font-weight: 600;
    line-height: 1.3;
}

.bg-primary {
    background-color: #130831;
}

// .spinner-container {
//   background-color: #130831 !important;
//   background-color: #faecd9 !important;
//   height: 100vh;
//   width: 100%;
// }
p {
    font-size: 15px;
    margin-bottom: 0 !important;
}

.text-white {
    color: #ffffff !important;
}

.text-sec {
    color: #19beec;
}

// ul {
//     list-style: none;
//     padding: 0 !important;
//     margin: 0;
// }
.fs-8 {
    font-size: 14px;
}

.text-gray-400 {
    color: $text-gray-400;
}

.text-primary {
    color: $secondaryColor !important;
}

.bg-sec {
    background-color: $secondaryColorDark;
    border: none !important;
}

.bg-lgt {
    background-color: $bg-lgt;
}

.card.rounded.h-100 {
    border: 1px solid #19beec;
}

.text-primary:hover {
    color: $primaryColor;
}

.landing-dark-bg {
    background-color: $primaryColor;
}

.border-none {
    border: none;
}

.text-drk {
    color: $text-dark;
}

.text-drk:hover {
    color: $secondaryColor;
}

.cursor-pointer {
    cursor: pointer;
}

.feature-section {
    background-image: url("../assets/popal-bnr.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.feature-section1 {
    // background-image: url('../assets/popal-bnr.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    background-color: $primaryColor;
    padding: 90px 0;
}

.svg-icon.svg-icon-success {
    color: #50cd89;
}

.table-header {
    display: flex;
    justify-content: space-between;
}

span.w-75.progress-txt {
    margin-left: 10px;
    margin-top: -2px;
    font-size: 14px;
}

.badge-light-success {
    background-color: #e8fff3;
    color: #50cd89;
    font-weight: 500;
}

.bg-info {
    background-color: #d2eef4 !important;
    color: #0dcaf0 !important;
    font-weight: 500;
}

.text-end {
    text-align: right !important;
}

a.viw {
    padding-right: 22px;
}

a {
    text-decoration: none !important;
}

button:focus {
    outline: none !important;
    // border: none !important;
    box-shadow: none !important;
}

.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}

li.page-item .page-link:focus {
    outline: none !important;
    box-shadow: none !important;
}

.btn-sm {
    padding: 10px 16px;
}

.min-w-190px {
    min-width: 190px;
}

.min-w-150px {
    min-width: 150px;
}

.w-420px {
    width: 420px;
}

.w-40 {
    width: 40%;
}

.w-60 {
    width: 60%;
}

.min-w-80px {
    min-width: 80px;
}

.min-w-80px {
    width: 150px !important;
}

.color-primary {
    color: #19beec;
}

.color-dark {
    color: #475467;
}

.h-6px {
    height: 6px !important;
}

.bar {
    margin-top: 8px;
}

i.fa.fa-plus.plus {
    font-size: 18px;
    margin-right: 8px;
}

.btn-primary {
    color: #fff;
    /* background-color: #007bff; */
    border-color: $secondaryColor !important;
    background: $secondaryColor !important;
    padding: 10px 20px;
}

.btn-light {
    color: $secondaryColor;
    /* background-color: #007bff; */
    border-color: $container-color !important;
    background: $container-color !important;
    padding: 10px 20px;
}

.btn-light:hover {
    color: #fff;
    background-color: $btn-hover !important;
    border-color: $btn-hover !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: $btn-hover !important;
    border-color: $btn-hover !important;
}

.border-bottom {
    border-bottom: 1px solid #eaecf0;
}

.border-top {
    border-top: 1px solid #eaecf0;
}

.border {
    border-bottom: 1px solid #eaecf0;
}

a.navbar-brand.lgo {
    padding-right: 26px;
}

.profile-icn {
    background: #ffffff;
    border: 1px solid rgba(102, 112, 133, 0.5);
    border-radius: 6px;
    align-items: center;
    padding: 6px 10px;
    text-align: center;
    justify-content: center;
    display: flex;
}

.btn-outline {
    border: 1px solid #19beec;
    background: transparent;
    color: #101828;
    padding: 10px 20px;
}

.btn-outline:hover {
    border: 1px solid #234779;
    background: #234779;
    color: #ffffff;
}

.btn-outline {
    outline: none;
}

// @keyframes spinner {
//     0% {
//       transform: rotate(0deg);
//     }
//     100% {
//       transform: rotate(360deg);
//     }
//   }
//   .loading-spinner {
//     width: 50px;
//     height: 50px;
//     border: 10px solid #f3f3f3; /* Light grey */
//     border-top: 10px solid #383636; /* Black */
//     border-radius: 50%;
//     animation: spinner 1.5s linear infinite;
//   }
.loading-spinner {
    width: 100px;
    height: 100px;
}

.bgi-size-cover {
    background-image: url(../assets/Section.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

input.form-control {
    font-size: 15px;
    line-height: 1.75;
}

.w-lg-50 {
    width: 50%;
}

.w-lg-45 {
    width: 40%;
}

.w-lg-590px {
    width: 590px !important;
}

.w-lg-420px {
    width: 420px !important;
}

.wrapper {
    min-height: 100vh;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.form-rem {
    display: flex;
    justify-content: space-between;
}

.link-primary {
    color: $secondaryColor;
}

.link-primary:hover {
    color: $primaryColor;
}

p.footr {
    bottom: 0;
    position: absolute;
    left: 35px;
    color: #475467;
    font-size: 14px;
}

.copyright-txt {
    justify-content: space-between;
    display: flex;
}

.feature-section {
    padding: 150px 0;
}

.nav-spsce.d-flex {
    justify-content: space-between;
}

img.google-icon {
    width: 2%;
    margin-right: 10px;
}

.modal-dialog.modal-90w.modal-dialog-centered {
    max-width: 70%;
    // max-height: 90%;
}

img.google-img {
    margin-right: 10px;
}

.btn-outline.google {
    padding: 6px 45px;
}

.text-lgt {
    color: #504e4e;
    font-size: 14px;
    font-weight: 500;
}

.text-center.modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal-header {
    border: none;
}

.trial.modal-header {
    padding-top: 70px;
}

.trial.modal-footer {
    margin-top: 70px;
}

// .footer {
//     bottom: 0;
//     display: flex;
//     position: fixed;
//     width: 100%;
//     background-color: #ffffff;
// }
.icon-color {
    color: #808390;
}

.overflow-txt {
    // display: inline-block;
    // width: 352px;
    // white-space: nowrap;
    // opacity: .75;
    // text-overflow: ellipsis;
    // overflow: hidden !important;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    color: #088ab2;
    text-decoration: underline;
}

.bg-custom-1 {
    background-color: #85144b;
}

.bg-custom-2 {
    background-image: linear-gradient(15deg, #13547a 0%, #80d0c7 100%);
}

//profile icon
.rounded-circle.profile-img {
    font-weight: 700;
    font-size: 14px;
    user-select: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex: 1 1 auto;
    // text-transform: uppercase;
    background-color: $secondaryColor;
    height: 35px;
    width: 35px;
    color: #ffffff;
}

.rounded-circle.profile-img1 {
    font-weight: 700;
    font-size: 14px;
    user-select: none;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex: 1 1 auto;
    // text-transform: uppercase;
    background-color: $secondaryColor;
    height: 42px;
    width: 42px;
    color: #ffffff;
}

.dropdown-toggle::after {
    position: absolute;
    top: 17px;
    right: -10px;
    color: $secondaryColor;
}

// .menus a:active {
//     // background-color: rgb(44, 196, 158);
//     color: red !important;
// }
nav a {
    color: #6f6f6f;
    font-weight: 400;
}

a.space-nav {
    padding-right: 30px;
}

nav a.active {
    color: $primaryColor;
}

nav a:hover {
    color: $primaryColor;
}

.menus_active {
    background-color: rgb(44, 196, 158);
    color: black;
}

.btn-ggl {
    padding: 8px 77px;
}

.dropdown-menu {
    padding: 0;
}

img.logo-auth {
    width: 170px;
}

img.logo-home {
    width: 170px;
}

img.feature-img {
    padding-left: 56px;
}

button.btn-close.close-icon {
    position: absolute;
    top: 18px;
    right: 20px;
}

button.btn-close.close-icon1 {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 15px;
}

.navbar-nav .dropdown-menu {
    margin-left: -208px;
    width: 300px;
    filter: drop-shadow(rgba(0, 0, 0, 0.2) 0px 0px 12px);
    border: none;
}

.dropdown-row {
    padding: 11px;
}

hr {
    margin: 0;
}

.col-name {
    display: inline-block;
    width: 225px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 13px;
    margin-left: -6px;
}

td {
    padding: 10px !important;
}

.table> :not(:last-child)> :last-child>* {
    border-bottom-color: #dee2e6;
}

.table-content {
    height: 900px;
}

.table-hght {
    height: 1600px;
}

.container.requirement-pg {
    height: 900px;
}

.min-w-30px {
    min-width: 30px !important;
}

.page-link {
    color: #088ab2;
}

.profile-header {
    justify-content: space-between;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: $secondaryColor;
}

a.dropdown-toggle.nav-link {
    color: #ffffff !important;
}

.col-2.d-inline-grid {
    display: inline-grid;
    padding: 0 !important;
}

//toastr
// .Toastify__toast-theme--light {
//     background: #234779 !important;
//     color: #ffffff !important;
// }
// .Toastify__toast-container {
//     width: 500px !important;
// }
.fa-spin {
    margin-left: -12px;
    margin-right: 8px;
}

.table-strp {
    background: #f4f4f4;
    padding: 10px 5px;
}

.text-blk {
    color: #0000008c;
    font-weight: 600;
}

img.logo-hom {
    width: 40%;
}

img.error-img {
    width: 90%;
}

.flex-root {
    flex: 1 1;
}

.flex-column-fluid {
    flex: 1 0 auto;
}

.not-found {
    height: 100vh;
}

p.note-txt.pt-1 {
    opacity: 0.8;
    font-size: 13px;
    font-style: italic;
}

a.link-clr {
    color: #0aaad6;
}

.accordion-button:not(.collapsed) {
    color: #234779;
    background-color: #f5f9ff;
    font-weight: 500;
}

// Responsive View 
@media (max-width: 1399.98px) {
    .mod-email {
        width: 34%;
    }
}

@media (max-width: 1199.98px) {
    .mod-email {
        width: 46%;
    }
    .bgi-size-cover {
        background-position: left;
    }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
    .navbar-nav .dropdown-menu {
        position: absolute !important;
        margin-left: 0;
    }
    button.btn.btn-primary.mobille-vw {
        width: 24%;
    }
    .modal-dialog.modal-90w.modal-dialog-centered {
        max-width: 95%;
    }
    .mod-email {
        width: 35%;
    }
    .w-lg-420px {
        width: 345px !important;
    }
    .wrapper {
        display: block !important;
    }
    .w-lg-50 {
        width: 100%;
    }
    .mobille-vw {
        margin-left: 0 !important;
    }
    .w-75.accordion {
        width: 100% !important;
    }
    .d-inline-mob {
        display: inline !important;
    }
    .cls-list.w-75 {
        width: 100% !important;
    }
}

@media (max-width: 767.98px) {
    button.btn.btn-primary.mobille-vw {
        width: 35%;
    }
    .mod-email {
        width: 45%;
    }
}

@media (max-width: 575.98px) {
    .modal-dialog.modal-90w.modal-dialog-centered {
        max-width: 100%;
    }
    .mod-email {
        width: 80%;
    }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
    .mod-email {
        width: 30%;
    }
}