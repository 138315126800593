.py-15 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
}

.px-10 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
}

.fs-3x {
    font-size: 3rem !important;
}

.fs-10 {
    font-size: 12px;
}

.py-10 {
    padding: 100px 0;
}