.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    gap: 3px;
}

.pagination .page-number {
    font-size: 14px;
    font-weight: 500;
    color: #088AB2;
    background: #fff;
    padding: 6px 12px;
    border-radius: .25rem;
    cursor: pointer;
    transition: all 0.5s ease;
}

.pagination .page-number:hover {
    background: #f3f6f7;
}

.pagination .active {
    color: #fff;
    background: #088AB2;
}

.pagination .active:hover {
    color: #088AB2;
    background: #fff;
}