$primaryColor: #234779;
$primaryColorr: #088AB2;
$primaryColor: #142c4b;
$secondaryColor: #19beec;
$secondaryColorDark: #19beec;
$container-color: #ffffff;
$text-dark: #6f6f6f;
$btn-hover: #234779;
$text-success: #50cd89;
$text-gray-400: #B5B5C3;
$body-font: 'Open Sans',
sans-serif;
$title-font: 'Poppins',
sans-serif;
// $btn-hover: #16a6ce;
$bg-color: #460b89;
$bg-lgt: #f7f7f9;
$title-color: #333333;
$text-lgt: #666666;
// $text-lgt: #767683;
$hover: #247aff;
$btn-hover-bg: #0b5ed7;
$body-font: 'Poppins',
sans-serif;
$header-height: 6rem;
$big-font-size: 3.5rem;
$h1-font-size: 46px;
$h2-font-size: 34px;
$h3-font-size: 1.5rem;
$h4-font-size: 1.10rem;
$normal-font-size: 1rem;
$small-font-size: 0.875rem;
$smaller-font-size: 0.813rem;
$tiny-font-size: 0.625;
//font weight
$font-normal: 400;
$font-medium: 500;
$font-semi-bold: 600;
//z index
$z-tooltip: 10;
$z-fized: 100;
$z-modal: 1000;