#side_nav {
    background-color: #f6f9fb;
    min-width: 290px;
    max-width: 290px;
    transition: all 0.2s;
}

.content {
    min-height: 100vh;
    width: 100%;
}

.sidebr.active {
    background-color: #e6e9eb;
}

a.text-decoration-none.sidebar-active.active {
    width: 100%;
    display: flex;
}

.sidebar li:hover {
    background-color: #f1f3f4;
}

.sidebar-head {
    display: flex;
    justify-content: center;
    -webkit-box-pack: center;
    padding: 24px 0 10px 0;
    ;
}

.rounded-circle.profile-img.profile-sidebar {
    height: 80px;
    width: 80px;
}

.sidebr {
    background-color: transparent;
    align-items: center;
    margin: 0px;
    padding: 0px 24px 0px calc(20px);
    height: 56px;
    display: flex;
    color: rgb(26, 26, 26);
    cursor: pointer;
    color: rgba(0, 0, 0, 0.55);
    font-weight: 500;
}

.sidebr:hover {
    background-color: #f1f3f4;
    color: rgba(0, 0, 0, .55);
    font-weight: 500;
}

.side-icon {
    padding-right: 20px;
}

.sidehead-text {
    overflow-wrap: anywhere;
}

@media (max-width: 767px) {
    #side_nav {
        margin-left: -290px;
        position: fixed;
        min-height: 100vh;
        z-index: 1;
    }
    #side_nav.active {
        margin-left: 0;
    }
    button.btn.close-btn {
        position: absolute;
        right: 0;
        font-size: 20px;
        top: 0;
    }
    button.btn.open-btn {
        position: absolute;
        left: -155px;
    }
    .content {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .w-100-mob {
        width: 100% !important;
    }
}